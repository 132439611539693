<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> CSVアップロード予約一覧 </CCardHeader>
        <vue-confirm-dialog></vue-confirm-dialog>
        <CCardBody>
          <div>
            <CRow class="mb-3">
              <CCol sm="10">
                <CButton color="primary" class="mr-2" type="submit" @click="goToCreatePage">新規予約作成</CButton>
              </CCol>
            </CRow>
          </div>
          <div v-if="loading">
            <LoadingCompoment />
          </div>
          <div v-else :key="key">
            <DataTable
              hover
              striped
              border
              :items="schedules"
              :fields="fields"
              :items-per-page="perPage"
              :active-page="page"
              :pagination-data="paginationData"
              :pagination="{ doubleArrows: false, align: 'center' }"
              :items-per-page-select="{
                  label: '表示件数',
                  values: [5, 10, 20, 30, 50, 100],
              }"    
              @page-change="pageChange"
              @pagination-change="selectChangePerpage"
            >
              <td slot="id" slot-scope="{ item }">
                <div> {{item.id || ''}}</div>
              </td>
              <td slot="csv_filename" slot-scope="{ item }">
                <div> {{item.csv_filename || ''}}</div>
              </td>
              <td slot="scheduled_at" slot-scope="{ item }">
                <div> {{item.scheduled_at || ''}}</div>
              </td>
              <td slot="status" slot-scope="{ item }">
                <div> {{item.schedule_status || ''}}</div>
              </td>
              <td slot="created_at" slot-scope="{ item }">
                <div> {{item.created_at || ''}}</div>
              </td>
              <td slot="updated_at" slot-scope="{ item }">
                <div> {{item.updated_at || ''}}</div>
              </td>
              <td slot="action" slot-scope="{item}" class="text-center">
                <div>
                  <CButton  @click="() => goToDetailPage(item)"><CIcon name="cil-magnifying-glass" /></CButton>
                  <CButton v-if="!item.status"  @click="() => handleConfirmDelete(item)"><CIcon name="cil-trash" /></CButton>
                </div>
              </td>
            </DataTable>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>  
</template>
<script>
import ResourceApi from '../../api/resourceApi';
import Vue from "vue"
import DataTable from "@/components/DataTable"

const apiImportSchedules = new ResourceApi('import-schedules');

export default {
  name: 'ImportSchedule',
  components: { DataTable },
  data() {
    return {
      schedules: [],
      loading: false,
      paginationData: {},
      page: 1,
      perPage: 30,
      query: {
        page: this.page,
        per_page: this.perPage,
      },
      fields:  [
        { key: 'id', label: 'ID' },
        { key: 'csv_filename', label: 'CSVファイル名' },
        { key: 'scheduled_at', label: 'スケジュール日時' },
        { key: 'status', label: 'ステータス' },
        { key: 'created_at', label: '作成日時' },
        { key: 'updated_at', label: '更新日時' },
        { key: 'action', label: '' },
      ],
      key: Math.random(),
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData (params) {
        this.loading = true
        this.key =  Math.random()
        this.query ={...this.query, ...params }
        apiImportSchedules.index(this.query).then(response => {
            let importSchedules = response.data.data;
            this.schedules = importSchedules;
            this.paginationData = response.data;
            this.loading = false
        }).catch(error => {
            this.loading = false
        })
    },
    goToCreatePage() {
      this.$router.push({path: `schedule/create`})
    },
    goToDetailPage(item) {
      this.$router.push({path: `schedule/${item.id}`})
    },
    handleConfirmDelete(item){
      let message = '予約を削除しますが宜しいでしょうか？'
      this.$confirm(
          {
            title: '確認',
            message: message,
            button: {
              no: 'いいえ',
              yes: 'はい'
            },
            callback: confirm => {
              if (confirm) {
                this.handleDeleteReservation(item)
              }
            }
          }
      )
    },
    handleDeleteReservation(item){
      apiImportSchedules.delete(item).then(response => {
        Vue.$toast.success('Deleted success');
        this.getData();
      }).catch(error => {
      })
    },
    pageChange (val) {
      this.page = val;
      this.getData({page: val});
    },
    selectChangePerpage (val) {
      this.perPage =  val  ? parseInt(val) : ''
      this.getData({per_page: val});
    },
  },
}
</script>
