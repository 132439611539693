var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._v(" CSVアップロード予約一覧 ")]),_c('vue-confirm-dialog'),_c('CCardBody',[_c('div',[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{attrs:{"sm":"10"}},[_c('CButton',{staticClass:"mr-2",attrs:{"color":"primary","type":"submit"},on:{"click":_vm.goToCreatePage}},[_vm._v("新規予約作成")])],1)],1)],1),(_vm.loading)?_c('div',[_c('LoadingCompoment')],1):_c('div',{key:_vm.key},[_c('DataTable',{attrs:{"hover":"","striped":"","border":"","items":_vm.schedules,"fields":_vm.fields,"items-per-page":_vm.perPage,"active-page":_vm.page,"pagination-data":_vm.paginationData,"pagination":{ doubleArrows: false, align: 'center' },"items-per-page-select":{
                label: '表示件数',
                values: [5, 10, 20, 30, 50, 100],
            }},on:{"page-change":_vm.pageChange,"pagination-change":_vm.selectChangePerpage},scopedSlots:_vm._u([{key:"id",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(" "+_vm._s(item.id || ''))])])}},{key:"csv_filename",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(" "+_vm._s(item.csv_filename || ''))])])}},{key:"scheduled_at",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(" "+_vm._s(item.scheduled_at || ''))])])}},{key:"status",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(" "+_vm._s(item.schedule_status || ''))])])}},{key:"created_at",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(" "+_vm._s(item.created_at || ''))])])}},{key:"updated_at",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(" "+_vm._s(item.updated_at || ''))])])}},{key:"action",fn:function(ref){
            var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',[_c('CButton',{on:{"click":function () { return _vm.goToDetailPage(item); }}},[_c('CIcon',{attrs:{"name":"cil-magnifying-glass"}})],1),(!item.status)?_c('CButton',{on:{"click":function () { return _vm.handleConfirmDelete(item); }}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1):_vm._e()],1)])}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }